// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBp6ACSRjOxdQsaKwFtx6kQimu5VR1rk5c",
  authDomain: "smarttenancy-b7997.firebaseapp.com",
  projectId: "smarttenancy-b7997",
  storageBucket: "smarttenancy-b7997.appspot.com",
  messagingSenderId: "380147100990",
  appId: "1:380147100990:web:8e14bb7b3d578a7412506f",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default getFirestore(); // this is db in app.js, Returns the existing Firestore instance that is associated with the provided FirebaseApp. If no instance exists, initializes a new instance with default settings.
