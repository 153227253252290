// import "core-js/stable";
// import "cross-fetch/polyfill"; //for IE fetch

// import "regenerator-runtime/runtime";
// require("es6-promise").polyfill();

import React from "react";
import ReactDOM from "react-dom";
// import './index.css'
import App from "./App";
import Modal from "react-modal";
Modal.setAppElement("#root"); // this is a must

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
  document.getElementById("root")
);
