import { useContext } from "react";

// import { MyContext } from "./MyContext";

function Home() {
  //   const { isPending } = useContext(MyContext);

  return (
    <>
      {/* {isPending && <div>Loading nzffd...</div>}
      <div>Home</div> */}
      <div className="hero Background d-flex align-items-center" id="home">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 mx-auto text-center">
              <h1 className="display-4 text-white">
                NZ Housing Analytic App in Time and Space
              </h1>
              <p className="text-white my-3">
                Navigate to Analytics Page: You can add/delete/edit/see records
                while doing real time analytics.
              </p>
              {/* <a href="#" className="btn me-2 btn-primary">
                Get Started
              </a>
              <a href="#" className="btn btn-outline-light">
                My Portfolio
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
