import React from "react";
import victor from "./ProfileCard/Dong.jpg"; // resize image to 128*128 px to fit the card
import "bootstrap-icons/font/bootstrap-icons.css";

function ProfileCard() {
  return (
    <>
      <div className="container my-auto">
        <h2 className="text-center text-dark"> People</h2>
        <p className="lead text-center text-dark mb-5"></p>
        <div className="row justify-content-center gy-5">
          <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <img src={victor} className="rounded-circle mb-3" alt="" />
                <h3 className="card-title mb-3">Dong Wang</h3>
                <p className="card-text">Data Scientist & App Developer</p>
                <a href="#">
                  <i className="bi bi-twitter text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram text-dark mx-1"></i>
                </a>
              </div>
            </div>
          </div>

          {/* <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <img
                  src="https://randomuser.me/api/portraits/women/11.jpg"
                  className="rounded-circle mb-3"
                  alt=""
                />
                <h3 className="card-title mb-3">Jane Doe</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda accusamus nobis sed cupiditate iusto? Quibusdam.
                </p>
                <a href="#">
                  <i className="bi bi-twitter text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram text-dark mx-1"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <img
                  src="https://randomuser.me/api/portraits/men/12.jpg"
                  className="rounded-circle mb-3"
                  alt=""
                />
                <h3 className="card-title mb-3">Steve Smith</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda accusamus nobis sed cupiditate iusto? Quibusdam.
                </p>
                <a href="#">
                  <i className="bi bi-twitter text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram text-dark mx-1"></i>
                </a>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body text-center">
                <img
                  src="https://randomuser.me/api/portraits/women/12.jpg"
                  className="rounded-circle mb-3"
                  alt=""
                />
                <h3 className="card-title mb-3">Sara Smith</h3>
                <p className="card-text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Assumenda accusamus nobis sed cupiditate iusto? Quibusdam.
                </p>
                <a href="#">
                  <i className="bi bi-twitter text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin text-dark mx-1"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram text-dark mx-1"></i>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ProfileCard;
