// import "core-js/stable";
// import "regenerator-runtime/runtime.js"; // this is not a must in parcel 2

import React from "react";
import { useState, useEffect, useMemo } from "react";
import Logo from "./components/house-user.svg";
import "./main.scss";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";

import {
  onSnapshot,
  collection,
  addDoc, //add new row
  setDoc, // update row
  doc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
import db from "./myfirebase"; // myfirebase js file
import { BrowserRouter, Routes, Route, Link, NavLink } from "react-router-dom";

import Analytics from "./components/Analytics";

import Error from "./components/Error";
import Home from "./components/Home";

import ProfileCard from "./components/ProfileCard";
import { MyContext } from "./components/MyContext";
function App() {
  const [TenacyData, setTenacyData] = useState([]);
  const [currentId, setCurrentId] = useState(""); // set currentId as empty strign is a must
  const [mapPosition, setmapPosition] = useState(false);
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const collectionRef = collection(db, "testdata");
      const q = query(collectionRef, orderBy("useid", "asc"));
      const unsub = onSnapshot(q, (snapshot) =>
        setTenacyData(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
      return unsub;
    }, 2000);
  }, []);

  // useMemo works too
  const providerValue = useMemo(
    () => ({
      TenacyData,
      setTenacyData,
      currentId,
      setCurrentId,
      mapPosition,
      setmapPosition,
      modalIsOpen,
      setmodalIsOpen,
    }),
    [TenacyData, currentId, mapPosition, modalIsOpen]
  );

  return (
    <BrowserRouter>
      <>
        <Navbar
          collapseOnSelect
          bg="dark"
          variant="dark"
          expand="lg"
          sticky="top"
        >
          <Container>
            {/* <Navbar.Brand href="#home">Real time analytics</Navbar.Brand> */}
            <Navbar.Brand href="#home">
              {
                TenacyData.length > 0 ? (
                  <div>
                    <img
                      alt=""
                      src={Logo}
                      width="30"
                      height="30"
                      className="d-inline-block align-top"
                    />{" "}
                    Real Time Analytics
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div
                      className="spinner-grow spinner-grow-sm text-white"
                      style={{ width: "0.75rem", height: "0.75rem" }}
                      role="status"
                    ></div>
                    <div className="text-white fs-6 fw-bold ms-2">
                      Loading...
                    </div>
                  </div>
                )
                //
              }
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto" variant="pills" defaultActiveKey="/">
                <Nav.Item>
                  <Nav.Link eventKey="1" as={NavLink} to="/">
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2" as={NavLink} to="/analytics">
                    Analytics
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="4" as={NavLink} to="/people">
                    People
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {console.log("2 page rendered")}
        <div>
          {/* <MyContext.Provider value="hhhtest"> */}
          <MyContext.Provider value={providerValue}>
            {/*the above is better*/}
            {/* <MyContext.Provider value={{ data, isPending, doThingsMemo }}> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/people" element={<ProfileCard />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </MyContext.Provider>
        </div>
      </>
    </BrowserRouter>
  );
}

export default App;
