import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
let schema = yup.object().shape({
  useid: yup
    .number()
    .integer()
    .typeError("number?")
    .min(0, "Min value 0")
    .max(9999, "Max value 9999")
    .required(),
  age: yup
    .number()
    .typeError("number?")
    .min(0, "Min value 0")
    .max(120, "Max value 120"),
  daysInAgreement: yup
    .number()
    .typeError("number?")
    .min(0, "Min value 0")
    .max(600, "Max value 600"),
  smokeAlarm: yup
    .number()
    .typeError("number?")
    .min(0, "Min value 0.")
    .max(5, "Max value 5"),
  rentPaid: yup.string(),
  firstname: yup.string(),
  city: yup.string(),
  lat: yup
    .number()
    .typeError("number?")
    .min(-46.5, "Min value -46.5")
    .max(-35.2, "Max value -35.2"),
  long: yup
    .number()
    .typeError("number?")
    .min(-176.5, "Min value -176.5")
    .max(178, "Max value 178"),
  // weeklyIncome: yup
  //   .number()
  //   .typeError("number?")
  //   .min(0, "Min value 0")
  //   .max(90000000, "Max value 90000000"),
});
const Forms = (props) => {
  const initialValues = {
    useid: "",
    age: "",
    daysInAgreement: "",
    smokeAlarm: "",
    rentPaid: "",
    firstname: "",
    city: "",
    lat: "",
    long: "",
    weeklyIncome: "",
  };
  const [values, setValues] = useState(initialValues);
  const { register, handleSubmit, errors, reset, clearErrors, setValue } =
    useForm({
      reValidateMode: "onSubmityy", // this prevents double typing!!
      resolver: yupResolver(schema),
    });
  useEffect(() => {
    // console.log(props.currentId);
    if (props.currentId === "") {
      setValues({ ...initialValues });
      clearErrors(); // clear the error message, if any
    } else {
      // console.log({
      //   ...props.TenacyData.filter(
      //     (character) => character.id === props.currentId
      //   )[0],
      // });
      setValues({
        ...props.TenacyData.filter(
          (character) => character.id === props.currentId
        )[0],
      });
      clearErrors(); // clear the error message, if any
    }
  }, [props.currentId, props.TenacyData]);

  const onSubmityy = (e) => {
    // console.log("after handleFormSubmit: ", props.currentId);
    // e.preventDefault(); this must be disabled when using yup or useForm
    // console.log("formvalues: ", values);
    props.addOrEdit(values);
    setValues({ ...initialValues });
  };
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  return (
    // className="row g-1" . g-* classes can be used to control the horizontal gutter widths
    <div className="container align-items-center">
      <div>
        <form
          autoComplete="off"
          className="row  justify-content-around"
          onSubmit={handleSubmit(onSubmityy)}
        >
          {/* <form className="row"> */}
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputID"
              name="useid"
              placeholder="ID (0~9999)"
              value={values.useid}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.useid && (
              <p>
                <small>{errors.useid.message}</small>
              </p>
            )}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputAge"
              name="age"
              placeholder="Age (0~120)"
              value={values.age}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.age && <p>{errors.age.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputDaysInAgreement"
              name="daysInAgreement"
              placeholder="TenancyDays (max 600)"
              value={values.daysInAgreement}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.daysInAgreement && <p>{errors.daysInAgreement.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputSmokeAlarm"
              name="smokeAlarm"
              placeholder="SmokeAlarm (0~5)"
              value={values.smokeAlarm}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.smokeAlarm && <p>{errors.smokeAlarm.message}</p>}
          </div>
          {/* use col-auto maybe better for input */}
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputRentPaid"
              name="rentPaid"
              placeholder="RentPaid"
              value={values.rentPaid}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.rentPaid && <p>{errors.rentPaid.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputFirstname"
              name="firstname"
              placeholder="Firstname"
              value={values.firstname}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.firstname && <p>{errors.firstname.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputCity"
              name="city"
              placeholder="City"
              value={values.city}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.city && <p>{errors.city.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputLat"
              name="lat"
              placeholder="Latitude (-46.5~-35.2)"
              value={values.lat}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.lat && <p>{errors.lat.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputLong"
              name="long"
              placeholder="Longitude (-176.5~178)"
              value={values.long}
              onChange={handleInputChange}
              ref={register}
            />
            {errors.long && <p>{errors.long.message}</p>}
          </div>
          <div className="col-sm-6 mb-1">
            <input
              type="text"
              className="form-control"
              id="inputWeeklyIncome"
              name="weeklyIncome"
              placeholder="IncomeByWeek(290,200,...)"
              value={values.weeklyIncome}
              onChange={handleInputChange}
              // ref={register}
            />
            {errors.weeklyIncome && <p>{errors.weeklyIncome.message}</p>}
          </div>
          <div className="row align-items-center my-2 text-center">
            <div className="col-6">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            <div className="col-6">
              <a
                className="btn text-danger"
                onClick={() => {
                  props.setCurrentId("");
                  setValues({ ...initialValues });
                  clearErrors(); // clear the error message, if any
                }}
              >
                Reset Form
                <i className="fas fa-power-off"></i>
              </a>
            </div>
          </div>
          {/* <div className="col-md-6">
          <a
            className="btn text-danger"
            onClick={() => {
              setValues({ ...initialValues });
              clearErrors(); // clear the error message, if any
            }}
          >
            Reset Form
            <i className="fas fa-power-off"></i>
          </a>
        </div> */}
          {/* </div> */}
        </form>
      </div>
    </div>
  );
};

export default Forms;
