import React from "react";
import Forms from "./Forms";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet"; // cause blank page in IE11, with error "SCRIPT1010: Expected identifier "
import { divIcon } from "leaflet";

import SimpleLinearRegression from "ml-regression-simple-linear";
import Plot from "react-plotly.js";

import ReactModal from "react-modal";
import { renderToStaticMarkup } from "react-dom/server";

import {
  onSnapshot,
  collection,
  addDoc, //add new row
  setDoc, // update row
  doc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { useEffect, useState, useRef, useContext } from "react";
import { MyContext } from "./MyContext";

import db from "../myfirebase"; // myfirebase js file
import { ReactTabulator, reactFormatter } from "react-tabulator";

import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
function Analytics() {
  const {
    TenacyData,
    setTenacyData,
    currentId,
    setCurrentId,
    mapPosition,
    setmapPosition,
    modalIsOpen,
    setmodalIsOpen,
  } = useContext(MyContext);

  // const [TenacyData, setTenacyData] = useState([]);
  // const [currentId, setCurrentId] = useState(""); // set currentId as empty strign is a must
  // const [mapPosition, setmapPosition] = useState(false);
  // const [modalIsOpen, setmodalIsOpen] = useState(false);

  // useEffect(() => {
  //   const collectionRef = collection(db, "testdata");
  //   const q = query(collectionRef, orderBy("useid", "asc"));
  //   const unsub = onSnapshot(q, (snapshot) =>
  //     setTenacyData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  //   );
  //   return unsub;
  // }, []);
  // console.log(TenacyData);
  // console.log("after edit/delete btn: ", currentId);
  const addOrEdit = async (obj) => {
    if (currentId === "") {
      const collectionRef = collection(db, "testdata");
      obj.weeklyIncome = obj.weeklyIncome.split(",").map(function (item) {
        return parseInt(item, 10);
      });
      const payload = obj;
      // console.log(payload);
      const docRef = await addDoc(collectionRef, payload);
      // console.log("The new ID is: " + docRef.id);
    } else {
      const docRef = doc(db, "testdata", currentId);
      // const payload = obj; // obj contain 'custom_update', 'custom_delete' field which cause unable to write into firebase
      // console.log("obj:", obj);
      let { custom_update, custom_delete, custom_details, ...payload } = obj;
      if (!Array.isArray(payload.weeklyIncome)) {
        // when update record, if weeklyIncome is modified by users, then the modified data become a string like "23,21,..."; if weeklyIncome is NOT modified, then such data is still an array, split function should not being triggered!!
        payload.weeklyIncome = payload.weeklyIncome
          .split(",")
          .map(function (item) {
            return parseInt(item, 10);
          });
      }
      // console.log("payload:", payload);
      await setDoc(docRef, payload);
      setCurrentId(""); // reset form to empty
    }
  };

  function EditBtn(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit";
    return (
      // <button
      //   onClick={() => {
      //     setCurrentId(rowData.id);
      //     // console.log(currentId); not working why
      //   }}
      // >
      //   {cellValue}
      // </button>
      <a
        className="btn text-primary"
        onClick={() => {
          setCurrentId(rowData.id);
        }}
      >
        <i className="fas fa-pencil-alt"></i>
        {cellValue}
      </a>
    );
  }

  function DelBtn(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Del";
    return (
      // <button
      //   onClick={() => {
      //     // console.log("DelBtn clicked: ", rowData.id);
      //     // console.log("DelBtn clicked-currentID: ", currentId);
      //     // const docRef = doc(db, "testdata", rowData.id);
      //     // deleteDoc(docRef);
      //     // setCurrentId("");
      //     //below wont work! becasue currentId not changed after setCurrentId(), use rowData.id
      //     // setCurrentId((old) => {
      //     //   return rowData.id;
      //     // });
      //     setCurrentId(rowData.id);
      //     // console.log("DelBtn clicked-after reset currentID: ", currentId);
      //     const docRef = doc(db, "testdata", currentId);
      //     deleteDoc(docRef);
      //     setCurrentId("");
      //   }}
      // >
      //   {cellValue}
      // </button>

      //

      <a
        className="btn text-primary"
        onClick={() => {
          if (
            window.confirm(`Are you sure to delete Case ID: ${rowData.useid} ?`)
          ) {
            const docRef = doc(db, "testdata", rowData.id); //use rowData.id not currentId
            deleteDoc(docRef);
            setCurrentId("");
          }
        }}
      >
        <i className="fas fa-trash-alt"></i>
        {cellValue}
      </a>
    );
  }

  function DetailsBtn(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "See";
    return (
      // <button
      //   onClick={() => {
      //     console.log("DelBtn clicked: ", rowData.id);
      //     const docRef = doc(db, "testdata", rowData.id);
      //     deleteDoc(docRef);
      //     setCurrentId("");
      //     //below wont work! becasue currentId not changed after setCurrentId(), use rowData.id
      //     // setCurrentId(rowData.id);
      //     // console.log("DelBtn clicked: ", currentId);
      //     // const docRef = doc(db, "testdata", currentId);
      //     // deleteDoc(docRef);
      //     // setCurrentId("");
      //   }}
      // >
      //   {cellValue}
      // </button>
      <a
        className="btn text-primary"
        onClick={() => {
          // const docRef = doc(db, "testdata", rowData.id); //use rowData.id not currentId
          // deleteDoc(docRef);
          // setCurrentId("");
          setmodalIsOpen(true);
          setCurrentId(rowData.id);
        }}
      >
        <i className="fas fa-eye"></i>
        {cellValue}
      </a>
    );
  }

  //Formatter to generate line chart
  const lineFormatter = function (cell, formatterParams, onRendered) {
    onRendered(function () {
      //instantiate sparkline after the cell element has been aded to the DOM
      $(cell.getElement()).sparkline(cell.getValue(), {
        width: "100%",
        type: "line",
        disableTooltips: false,
        spotRadius: 2.3,
      });
    });
  };
  const columns = [
    {
      title: "ID",
      field: "useid",
      width: 30,
      // dir: "asc",// wont work
    },
    {
      title: "Update",
      field: "custom_update",
      hozAlign: "center",
      // editor: "input",
      formatter: reactFormatter(
        <EditBtn
        // onSelect={(name) => {
        //   setTestFirstname(name);
        //   alert(name);
        // }}
        />
      ),
    },
    {
      title: "Delete",
      field: "custom_delete",
      hozAlign: "center",
      // editor: "input",
      formatter: reactFormatter(
        <DelBtn
        // onSelect={(name) => {
        //   setTestFirstname(name);
        //   alert(name);
        // }}
        />
      ),
    },
    {
      title: "Details",
      field: "custom_details",
      hozAlign: "center",
      // editor: "input",
      formatter: reactFormatter(
        <DetailsBtn
        // onSelect={(name) => {
        //   setTestFirstname(name);
        //   alert(name);
        // }}
        />
      ),
    },
    {
      title: "Name",
      field: "firstname",
      width: 120,
      headerFilter: "input",
      // editor: "input",
    },
    {
      title: "IncomeHistory",
      field: "weeklyIncome",
      width: 150,
      formatter: lineFormatter,
      // editor: "input",
    },

    {
      title: "TenancyDays",
      field: "daysInAgreement",
      width: 130,
      // editor: "input",
    },
    {
      title: "Age",
      field: "age",
      hozAlign: "left",
      formatter: "progress",
      width: 80,
      // editor: "input",
    },
    { title: "City", field: "city", width: 100, editor: "input" },
    {
      title: "SmokeAlarms",
      field: "smokeAlarm",
      hozAlign: "center",
      formatter: "star",
      // editor: "input",
    },

    {
      title: "rentPaid?",
      field: "rentPaid",
      hozAlign: "center",
      // formatter: "tickCross",
      // editor: true,
    },
    {
      title: "Latitude",
      field: "lat",
      width: 150,
      // editor: "input",
    },
    {
      title: "Longitude",
      field: "long",
      width: 150,
      // editor: "input",
    },
  ];
  const options = {
    height: 250,
    movableRows: true,
    // autoResize: true,
    pagination: "local",
    paginationSize: 5,
    // initialSort: [
    //   { column: "useid", dir: "asc" }, //sort by this first
    // ],
  };
  const ref = useRef();
  const rowClick = (e, row) => {
    console.log(ref.current.props.columns); // ref is the Tabulator table instance
    console.log(`rowClick id: ${row.getData().id}`);
    console.log(row.getData());
    console.log(row);
    console.log(e);
    // console.log(TenacyData);
    // setmodalIsOpen(true);
    // setmyname(row.getData().name);
    // setmydata(row.getData().line);
    // setmyid(row.getData().id);
  };
  //map
  const iconMarkup = renderToStaticMarkup(
    <i className="fas fa-home fa-2x" style={{ color: "red" }} />
  );
  const customMarkerIcon = divIcon({
    iconSize: 0, // make white square around font-awesome go away
    // iconAnchor: 1, // not usefule
    html: iconMarkup,
  });

  // use SimpleLinearRegression from 'ml-regression-simple-linear';
  const regression = new SimpleLinearRegression(
    TenacyData.map((a) => a.age).map(Number), //map(Number) is important here, to convert string into number
    TenacyData.map((a) => a.daysInAgreement).map(Number)
  );
  const age = TenacyData.map((data) => data.age);
  const xMax = Math.max(...age);
  return (
    <>
      {/* {`currentID is: ${currentId}`} */}
      <div className="container shadow my-auto">
        <h5>NZ Smart Tenancy 🏘️ by DongWang</h5>
        <div className="row gap-2 justify-content-center">
          <div className="my-1  col-lg-6 col-xl-5 border border-2 bg-light">
            <div>
              <h5>Add/Edit Records in Database with form validation</h5>
              <Forms {...{ addOrEdit, currentId, TenacyData, setCurrentId }} />
            </div>
          </div>
          <div className="my-1  col-lg-5 col-xl-6 border border-2 bg-light">
            <div>
              <h5>Records in Database</h5>
              <ReactTabulator
                ref={ref}
                data={TenacyData}
                columns={columns}
                layout={"fitData"}
                // rowClick={rowClick}
                options={options}
              />
            </div>
          </div>
        </div>
        <div className="row gap-2 justify-content-center">
          <div className="my-1 col-lg-5 border border-2 bg-light">
            <h5>Regression Analysis in real time</h5>
            <div
              className="col-lg-12"
              style={{
                backgroundColor: "rgb(213, 228, 235)",
              }}
            >
              <Plot
                config={{ displayModeBar: false }}
                data={[
                  {
                    x: TenacyData.map((a) => a.age),
                    y: TenacyData.map((a) => a.daysInAgreement),
                    type: "scattergl",
                    mode: "markers",
                    marker: { color: "green", size: 12, opacity: 0.5 },
                    name: "Applicants",
                    hoverinfo: "x+y",
                    text: TenacyData.map((a) => a.useid),
                    hovertemplate:
                      "<i>Days In Tenancy</i>: %{y:.0f}" +
                      "<br><b>Age </b>: %{x}<br>" +
                      "<b><b>Case ID </b>: %{text}</b>",
                  },
                  {
                    x: [0, xMax],
                    y: [regression.intercept, regression.predict(xMax)],
                    mode: "lines",
                    opacity: 0.7,
                    line: {
                      color: "rgb(55, 128, 191)",
                      width: 3,
                    },
                    name: `trend (r\xB2: ${regression //Hexadecimal escapes started by "\x",
                      .score(
                        TenacyData.map((a) => a.age).map(Number),
                        TenacyData.map((a) => a.daysInAgreement).map(Number)
                      )
                      .r2.toFixed(3)})`,
                  },
                ]}
                layout={{
                  xaxis: { title: "Age" },
                  yaxis: { title: "Days In Tenancy" },
                  title: "Days In Tenancy ~ Age",
                  // subtitle
                  annotations: [
                    {
                      text: `f(x)= ${regression.slope.toFixed(1)}*x ${
                        regression.intercept.toFixed(1) < 0 ? "" : "+"
                      } ${regression.intercept.toFixed(1)}`,
                      font: {
                        size: 10,
                        color: "rgb(116, 101, 130)",
                      },
                      showarrow: false,
                      // align: "center",
                      x: 0.3,
                      y: 0.2,
                      xref: "paper",
                      yref: "paper",
                    },
                  ],
                  autosize: true,
                  legend: {
                    x: 1,
                    y: 1,
                    traceorder: "normal",
                    xanchor: "right", // put legend  inside the plot
                    font: {
                      family: "sans-serif",
                      size: 12,
                      color: "black",
                    },
                    bgcolor: "rgba(0, 0, 0, 0)",
                    // bordercolor: "grey",
                    borderwidth: 1,
                  },
                  margin: {
                    l: 50,
                    r: 0,
                    b: 30,
                    t: 30,
                    pad: 2,
                  },
                  plot_bgcolor: "rgb(213, 228, 235)",
                  paper_bgcolor: "rgb(213, 228, 235)",
                }}
                useResizeHandler={true}
                // width: "100%" is must, height is plot height
                style={{ width: "100%", height: "35vh" }} // match height with map in scss file
              />
            </div>
          </div>
          <div className="my-1 col-lg-6 border border-2 bg-light">
            <h5>Spatial Analysis (to be done)</h5>
            <div className="col-lg-12">
              <MapContainer
                center={[-41.3089, 174.7772]}
                zoom={5}
                scrollWheelZoom={false}
              >
                {/* <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
            /> */}
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Dong Wang'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {TenacyData.map((mycase) => {
                  // console.log(mycase.id);
                  return (
                    <Marker
                      key={mycase.id}
                      position={[+mycase.lat, +mycase.long]}
                      icon={customMarkerIcon}
                    >
                      <Popup
                        position={[+mycase.lat, +mycase.long]}
                        onClose={() => {
                          setCurrentId("");
                        }}
                      >
                        <div>
                          <h6>{`Case ID: ${mycase.useid}`}</h6>
                          <p>{`Name: ${mycase.firstname}`}</p>
                          <p>{`City: ${mycase.city}`}</p>
                          <p>{`Age: ${mycase.age}`}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                })}
                {currentId && (
                  <Popup
                    position={[
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .lat,
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .long,
                    ]}
                    onClose={() => {
                      setCurrentId("");
                    }}
                  >
                    <div>
                      <h6>{`Update Case ID : ${
                        TenacyData.filter(
                          (mycase) => mycase.id === currentId
                        )[0].useid
                      } ?`}</h6>
                      <p>{`Name : ${
                        TenacyData.filter(
                          (mycase) => mycase.id === currentId
                        )[0].firstname
                      }`}</p>
                      <p>{`City : ${
                        TenacyData.filter(
                          (mycase) => mycase.id === currentId
                        )[0].city
                      }`}</p>
                      <p>{`Age : ${
                        TenacyData.filter(
                          (mycase) => mycase.id === currentId
                        )[0].age
                      }`}</p>
                    </div>
                  </Popup>
                )}
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={() => setmodalIsOpen(false)}// this must disabled, otherwise there is a bug on currentId
        style={{
          overlay: {
            zIndex: 1000, // import to set this on top of every component
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgb(213, 228, 235,0.5)",
          },
          content: {
            position: "absolute",
            top: "150px",
            left: "130px",
            right: "130px",
            bottom: "150px",
            border: "1px solid #ccc",
            color: "navy",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div className="row">
          <div className="col-lg-6 text-center border border-5">
            <h2>Applicants Profile</h2>
            <h2>{""}</h2>
            <h3>
              {/* modalIsOpen here is a must */}
              {modalIsOpen &&
                `Name: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0]
                    .firstname
                }`}
            </h3>
            <h5>
              {modalIsOpen &&
                `ID: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0]
                    .useid
                }`}
            </h5>
            <h5>
              {modalIsOpen &&
                `Age: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0].age
                }`}
            </h5>
            <h5>
              {modalIsOpen &&
                `Days In Tenancy: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0]
                    .daysInAgreement
                }`}
            </h5>
            <h5>
              {modalIsOpen &&
                `City: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0].city
                }`}
            </h5>
            <h5>
              {modalIsOpen &&
                `Rent Paid? : ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0]
                    .rentPaid
                    ? "Yes"
                    : "No"
                }`}
            </h5>
            <h5>
              {modalIsOpen &&
                `Smoke Alarm Numbers: ${
                  TenacyData.filter((mycase) => mycase.id === currentId)[0]
                    .smokeAlarm
                }`}
            </h5>
          </div>
          <div className="col-lg-6 border border-5">
            <MapContainer
              className="react-modal-map"
              center={[-41.3089, 174.7772]}
              zoom={5}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> Dong Wang'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {TenacyData.filter((mycase) => mycase.id === currentId).map(
                // loop is a must, even only one record
                (mycase) => {
                  // console.log(mycase.id);
                  return (
                    <Marker
                      key={mycase.id}
                      position={[+mycase.lat, +mycase.long]}
                      icon={customMarkerIcon}
                    >
                      <Popup
                        position={[+mycase.lat, +mycase.long]}
                        onClose={() => {
                          // setCurrentId("");// should not set currentId here
                        }}
                      >
                        <div>
                          <h6>{`Case ID: ${mycase.useid}`}</h6>
                          <p>{`Name: ${mycase.firstname}`}</p>
                          <p>{`City: ${mycase.city}`}</p>
                          <p>{`Age: ${mycase.age}`}</p>
                        </div>
                      </Popup>
                    </Marker>
                  );
                }
              )}
              {currentId && (
                <Popup
                  position={[
                    TenacyData.filter((mycase) => mycase.id === currentId)[0]
                      .lat,
                    TenacyData.filter((mycase) => mycase.id === currentId)[0]
                      .long,
                  ]}
                  onClose={() => {
                    // setCurrentId("");// should not set currentId here
                  }}
                >
                  <div>
                    <h6>{`Case ID : ${
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .useid
                    } `}</h6>
                    <p>{`Name : ${
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .firstname
                    }`}</p>
                    <p>{`City : ${
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .city
                    }`}</p>
                    <p>{`Age : ${
                      TenacyData.filter((mycase) => mycase.id === currentId)[0]
                        .age
                    }`}</p>
                  </div>
                </Popup>
              )}
            </MapContainer>
          </div>
        </div>
        {""}
        <div className="text-center mt-5">
          <button
            className="btn btn-primary"
            onClick={() => {
              setmodalIsOpen(false);
              setCurrentId(""); //reset important
            }}
          >
            Close
          </button>
        </div>
      </ReactModal>
    </>
  );
}

export default Analytics;
